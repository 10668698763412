@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;400;500;600;700;800&display=swap');

.matchAnim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  flex-direction: column;
  background: rgba(0,0,0,0.6);
  animation: fadeOut ease-in-out 0.3s 0.8s forwards;
}

.matchAnim p {
  font-weight: 600;
  font-size: 40px;
  margin: 0;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.matchAnim p:first-child {
  animation: ease-out 0.5s 0s forwards goDown;
}

.matchAnim p:nth-child(2) {
  animation: ease-out 0.5s 0.1s forwards goDown;
}

.matchAnim p:last-child {
  animation: ease-out 0.5s 0.2s forwards goDown;
}

@keyframes goDown {
  0% {
    transform: translateY(-20px);
    color:black;
  }
  60% {
    transform: translateY(-5px);
    color: red;
  }
  100% {
    transform: translateY(0);
    color: black;
  }
}

.checkemailp {
  width: 85%;
  font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 38px;
display: flex;
align-items: center;
margin: 0;
color: #000000;
}

.checkemailinput {
  background: rgba(0, 0, 0, 0.09);
border: 1px solid #000000;
border-radius: 10px;
width: 85%;
font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 500;
font-size: 22px;
color: black;
line-height: 33px;
box-sizing: border-box;
margin-top: 20px;
margin-bottom: 20px;
padding: 12px 24px;
}

.demandeJeanGuile {
  width: 90%;
  padding: 23px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.26);
  border-radius: 10px;
}

.demandeJeanGuile p {
  font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 33px;
text-decoration-line: underline;
margin: 0;
width: 100%;
text-align: center;
color: #FFFFFF;
}

.choixpersonnes {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.imageP1 {
  border: 4px solid #00FF38;
border-radius: 355px;
width: 65px;
height: 65px;
margin-right: 8px;
}

.imageP2 {
  border: 4px solid #FFB800;
  border-radius: 355px;
  width: 65px;
  height: 65px;
  margin-left: 8px;
}

.jyvais {
  background: #FDFDAC;
border-radius: 10px;
width: 70%;
height: 50px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 33px;
}

.buttonbase {
  transform: scale(1);
  transition: transform 0.2s;
}

.buttonbase:active {
  transform: scale(0.8);
}

.App {
  text-align: center;
}

.profil {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 34px;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.topText {
  margin-top: 13px;
  background: rgba(0, 0, 0, 0.15);
  border: 3px dashed #FDFDAC;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  padding: 0 12px;
  box-sizing: border-box;
}

textarea::placeholder {
  color:rgba(255, 255, 255, 0.8)
}



.profil textarea {
  padding: 13px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 12px;
height: 118px;
font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 23px;
margin-top: 13px;

color: #FFFFFF;
background: rgba(0, 0, 0, 0.15);
border: 3px dashed #FDFDAC;
border-radius: 10px;
}

.topText input {
  background: transparent;
  border: none;
  color: #FFFFFF;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
}

.ensembleprofil {
  width: 100%;
  position: relative;
  border: 3px dashed #FDFDAC;
  border-radius: 10px;
  margin-top: 13px;
}

.ensembleprofil input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.resultttest {
  position: absolute;
  width: 80px;
  height: 80px;
  right: -20px;
  top: -40px;
  
  border: 7px solid #FDFDAC;
  border-radius: 720px;
}

.photoprofil {
  width: 100%;
  aspect-ratio: 5/6;
}

.topText img {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.messageTime {
  opacity: 0.6;
  margin: 0 3px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.message {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.messagetext {
  border-radius: 10px;
  padding: 15px;
  max-width: 70%;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  line-height: 28px;
}

.convplace {
  height: 100%;
  overflow: scroll;
  width: 100%;
  box-sizing: border-box;
  padding: 14px;
}

.chat {
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.ecriture {
  width: 90%;
  border-radius: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 16px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.19);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ecriture img {
  width: 35px;
}

.ecriture input::placeholder {
  color: black;
  color: rgba(253, 253, 172, 0.7);
}

.ecriture input {
  flex: 1;
  background: none;
  border: none;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;

  color: rgba(253, 253, 172, 1);
}

input:focus, textarea:focus {
  outline: none;
}

.conversation {
  width: 90%;
  background: rgba(0, 0, 0, 0.31);
  border-radius: 10px;
  display: flex;
  box-sizing: border-box;
  padding: 17px 19px;
  align-items: center;
  height: 85px;
  margin: 15px auto;
}

.conversation img {
  width: 55px;
  border-radius: 100px;
  height: 55px;
  margin-right: 13px;
}

.conversationText p {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  color: #FFFFFF;
  max-height: 36px;
  font-weight: normal; 
  max-height: 36px; 
  text-overflow: ellipsis; 
  overflow: hidden;
}

.conversationText p:first-child {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #FDFDAC;

}



.filtertext {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  width: 80%;
  text-align: center;
  line-height: 33px;
  margin: 0;
  margin-bottom: 15px;
  color: #000000;
}

.filterbutton {
  transform: scale(1);
  transition: 0.4s;
  width: 80%;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.49);
  border-radius: 10px;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #000000;

}


.filterbutton:active {
  transform: scale(0.8);
}

.filterimages {
  margin-bottom: 15px;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.persActive,
.persInactive {
  border-radius: 720px;
  width: 100px;
  height: 100px;
  margin: 10px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.persActive img,
.persInactive img {
  width: 100px;
  height: 100px;
}

.persInactive {
  border: 3px solid #2C2C2A;
}

.persInactive div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.4;
}


.persActive {
  border: 3px solid #FDFDAC;
}

.titre_nav {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  text-align: center;

  color: #FFFFFF;
}

.question p {
  font-family: 'Be Vietnam Pro';
}

.filtrepage {
  z-index: 4;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.65);
  backdrop-filter: blur(15px);
}

.profilFit {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 130.5%;
  width: 80%;
  text-align: center;
  animation: fadeIn 3.6s ease-in-out;
}

@keyframes fadeIn {

  0%,
  50%,
  60% {
    opacity: 0;
  }

  39%,
  100% {
    opacity: 1;
  }
}

.matchPage {
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.filtre {
  width: 200px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.38);
}


.persfiltere {
  width: 37px;
  border-radius: 100px;
  border: 3px solid rgba(0, 0, 0, 0.48);
  margin-left: 10px;
}

.likedislike {

  display: flex;
  flex-direction: row;
  margin-top: 24px;

}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-top: 5px;
  background: #FDFDAC;
}


.actionlike {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.imgBottom {
  width: 32%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.imgBottom img {
  width: 36px;
  object-fit: contain;
}

.separator {
  background: black;
  opacity: 0.2;
  width: 3px;
  height: 42px;
}

.actionlike div {
  transform: scale(1);
  transition: 0.3s;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
  border-radius: 10px;
}

.actionlike div:active {
  transform: scale(0.8);
}

.actionlike:first-child {
  margin-right: 10px;
}

.actionlike:last-child {
  margin-left: 10px;
}

.actionlike div img {
  width: 60px;
}

.actionlike p {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin-top: 5.3px;
  color: #FDFDAC;
  width: 110px;
}

.plusIcon {
  width: 28px;
  height: 28px;
}

.filtre p {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-left: 10px;
  color: #FDFDAC;
}

.personneMatch {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  width: 90%;
}

.topNameMatch {
  width: 100%;
  height: 174px;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.82) 0%, rgba(217, 217, 217, 0) 100%);
}

.topNameMatch p {
  margin-top: 13px;
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  text-align: center;

  color: #FDFDAC;
  width: 100%;
  text-align: center;
}

.personneDesc {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  background: black;
  align-items: center;
  padding: 0 10px;
  margin-top: -7px;
  justify-content: space-between;
}

.personneDesc p {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  height: 55px;
  overflow: scroll;
  color: #FFFFFF;
  margin: 0;
  margin-right: 8px;
}

.personneDesc img {
  width: 50px;
  height: 50px;
  margin: 0px;
  border: 5px solid #FDFDAC;
  border-radius: 720px;

}