.little_text {
    font-family: 'Be Vietnam Pro';
font-style: normal;
font-weight: 500;
font-size: 22px;
line-height: 28px;
text-align: center;
    width: 80%;
color: #FDFDAC;
}

.titre {
    font-family: 'Commissioner';
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 73px;

color: #FDFDAC;
}

.accueil_groupe {
    width: 80%;
}

